import {createRouter, createWebHistory} from 'vue-router';
import routes from '@/router/routes';
import {default as store} from "../store";
import notification from "@/helper/notification";

// @ts-ignore
const router = createRouter({
  // @ts-ignore
  history: createWebHistory(),
  routes,
});

let user = store.getters['UserModule/user'];

router.beforeEach(async (to, from, next) => {

  /**
   * Se for logout ou login, nao força em fazer a verificação
   */

  // @ts-ignore
  if (to?.meta?.rules?.includes('guest')) {
    next();
    return
  }

  /**
   * Se for diferente disso, entao pesquisa se está online
   */
  if (!store.getters['UserModule/isOnline']) {
    try {
      await store.dispatch('UserModule/fetchUser');
      user = store.getters['UserModule/user'];
      if (!user?.id) {
        router.push('/login')
      }
    } catch (e) {
    }
  }

  let hasPermission = await checkPermission(to)

  if (!hasPermission) {
    notification.showError('Você não tem permissão para acessar essa página');
    next('/home')
  }
  let route = reroute(to);

  if (route) {
    next(route);
  } else {
    next();
  }
});

const checkPermission = async (route) => {
  if (route?.meta?.rules?.includes('auth')) {
    let user = await store.getters['UserModule/user']
    if (route.matched[0].name !== 'home') {
      if(!user.occupation.is_gestor_nacional) {
        // return await user.occupation.permissions.find((permission) => permission.menu.name === route.matched[0].name)
        // return await user.permissions.includes(route.matched[0].name)
        return user.occupation.permissions.find((permission) => permission.menu.name === route.matched[0].name) &&
          user.menu.some(menu => menu.menu.name === route.matched[0].name)
      }
    }
  }
  return true
}


const rules = {
  // @ts-ignore
  guest: {fail: 'home', check: () => !store.getters['UserModule/isOnline']},
  // @ts-ignore
  auth: {fail: 'login', check: () => store.getters['UserModule/isOnline']},
};

function reroute(to) {

  let failRoute = false,
    checkResult = false;

  to.meta.rules &&
  to.meta.rules.forEach(rule => {
    let check = false;
    if (Array.isArray(rule)) {
      let checks = [];
      for (let i in rule) {
        // @ts-ignore
        checks[i] = rules[rule[i]].check();
        check = check || checks[i];
      }
      if (!check && !failRoute) {
        // @ts-ignore
        failRoute = rules[rule[checks.indexOf(false)]].fail;
      }
    } else {
      check = rules[rule].check();
      if (!check && !failRoute) {
        failRoute = rules[rule].fail;
      }
    }
    checkResult = checkResult && check;
  });
  if (!checkResult && failRoute) {
    return {name: failRoute};
  }
  return false;
}

export default router;
