// @ts-ignore
import Api from '~/api/Api';

class Auth extends Api {
  constructor(url:string) {
    super(url);
  }

  async token(form: any) {
    return await super.post(`${super.getUrl()}/token`, form).then((res:any) => super.success(res));
  }

  async me() {
    const headers = {
      showGlobalLoading: false
    }
    return await super.get(`${super.getUrl()}/me`, undefined, headers).then((res:any) => super.success(res));
  }

  async login(form: any) {
    return await super.get('/sanctum/csrf-cookie').then(data => {
      return super.post(`${super.getUrl()}/login`, form).then((res:any) => super.success(res));
   })
      .catch((err) => {
        return super.fail(err)
      })
  }

  async logout(form: any) {
    return await super.post(`${super.getUrl()}/logout`, form).then((res:any) => super.success(res));
  }

  async recoverPassword(form: any) {
    return await super
      .post(`${super.getUrl()}/recover-password`, form)
      .then((res:any) => super.success(res))
      .catch((err) => super.fail(err));
  }

  async register(form: any) {
    return await super.post(`${super.getUrl()}/register`, form).then((res:any) => super.success(res));
  }

  async resetPassword(form: any) {
    return await super.post(`${super.getUrl()}/reset-password`, form)
      .then((res:any) => super.success(res))
      .catch((error) => super.fail(error));
  }

  async verifyTwoFA(form: any) {
    return await super.post(`${super.getUrl()}/two-factor-challenge`, form).then((res:any) => super.success(res));
  }

  async TwoFAconfirmPassword(form: any) {
    return await super.post(`${super.getUrl()}/users/confirm-password`, form).then((res:any) => {
      return super.post(`${super.getUrl()}/user/two-factor-authentication`).then((res:any) => super.success(res));
    });
  }

  async getTwoFAQRCode(form: any) {
    return await super.get(`${super.getUrl()}/user/two-factor-qr-code`).then((res:any) => super.success(res));
  }

  async disableTwoFAQRCode(form: any) {
    return await super.delete(`${super.getUrl()}/user/two-factor-authentication`).then((res:any) => super.success(res));
  }

  async checkHashResetPassword(hash: string) {
    return await super.get(`${super.getUrl()}/check-hash-reset-password/${hash}`)
      .then((res:any) => super.success(res))
      .catch((error) => super.fail(error));
  }
}

export default new Auth('auth');
