// @ts-ignore
import Api from '~/api/Api';

class Application extends Api {
  constructor(url: string) {
    super(url);
  }

  async version() {
    try {
      return await super.get(`${super.getUrl()}/version`)
        .then((res: any) => super.success(res));
    } catch (error: any) {
      return super.fail(error);
    }
  }


}

export default new Application('application');
