<template>
    <v-overlay
      v-model="overlay"
      class="align-center justify-center"
    >
<!--      <template #activator="{ isActive, props }">-->
<!--        <iframe-->
<!--          src="https://embed.lottiefiles.com/animation/56302"-->
<!--          v-if="isActive"-->
<!--          class="align-center justify-center"-->
<!--        >-->

<!--        </iframe>-->
<!--        <v-btn v-bind="props">Overlay is {{ isActive ? 'open' : 'closed' }}</v-btn>-->
<!--      </template>-->
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
        style="color: #77D8C6"
      />
    </v-overlay>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import {useStore} from "vuex";

const store = useStore()
const overlay = computed(() =>  store.getters["LoadingModule/overlay"])
const isLoading = ref(true)
const axiosInterceptor = ref(null);

const enableInterceptor = () => store.dispatch("LoadingModule/enableInterceptor")
const change = () => store.dispatch("LoadingModule/change")

onMounted(() => {
  enableInterceptor()
})

</script>
