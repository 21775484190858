import {UserInterface} from "@/interfaces";
import {Auth} from "@/api";
import state from "@/api/State";

export const UserModule = {
  namespaced: true,
  state: {
    user: <UserInterface>{
      name: null,
      email: null
    },
    online: false,
    logout: false,
    versionBack: '',
    versionFront: '',
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
      state.online = true
    },
    SET_AUTH_FAILURE(state) {
      state.user = {
        name: null,
        email: null
      };
      state.online = false
    },
    SET_STATUS(state, status) {
      state.online = status
    },
    SET_VERSION_BACK(state, version) {
      state.versionBack = version
    },
    SET_VERSION_FRONT(state, version) {
      state.versionFront = version
    },
    SET_LOGOUT(state, status) {
      state.logout = status
      state.user = {
        name: null,
        email: null
      };
      state.online = false
      document.cookie = "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "bav_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
  },
  actions: {
    setVersionBack({commit}, version) {
      commit('SET_VERSION_BACK', version)
    },
    setVersionFront({commit}, version) {
      commit('SET_VERSION_FRONT', version)
    },
    setUser({commit}, user) {
      commit('SET_USER', user)
    },
    setOnline({commit}) {
      commit('SET_STATUS', true)
    },
    setOffline({commit}) {
      commit('SET_STATUS', false)
    },
    async setLogout({commit}) {
      commit('SET_LOGOUT', true)
    },
    async fetchUser({commit}) {

      commit('SET_VERSION_FRONT', process.env.VUE_APP_VERSION_FRONT)

      try {
        // @ts-ignore
        const {data, ok} = await Auth.me();
        if (ok) {
          commit('SET_USER', data);
          commit('SET_STATUS', true);
        } else {
          commit('SET_AUTH_FAILURE');
        }
      } catch (e) {
        commit('SET_AUTH_FAILURE');
      }
    },

  },
  getters: {
    user(state): UserInterface {
      return state.user
    },
    isOnline(state) {
      return (state.online)
    },
    isLogout(state) {
      return (state.logout)
    },
    versionBack(state) {
      return (state.versionBack)
    },
    versionFront(state) {
      return (state.versionFront)
    }
  }
}
