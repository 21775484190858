// @ts-ignore
import * as Component from './components';
import system from "@/helper/system";

const routes = [
  ...applyRules(
    ['guest'],
    [
      {
        path: '',
        component: Component.DefaultWrapper,
        redirect: {path: '/login'},
        children: [
          {
            path: '/login',
            component: Component.LayoutPublic,
            children: [
              {path: '', name: 'login', component: Component.Login},
            ],
          },
          {
            path: '/logout',
            component: Component.LayoutPublic,
            children: [
              {path: '', name: 'logout', component: Component.Logout},
            ],
          },
          {
            path: '/forgot-password',
            component: Component.LayoutPublic,
            children: [
              {path: '', name: 'forgot-password', component: Component.ForgotPassword},
            ],
          },
          {
            path: '/reset-password/:hash',
            component: Component.LayoutPublic,
            children: [
              {path: '', name: 'reset-password', component: Component.ResetPassword},
            ],
          },
          {
            path: '/register',
            component: Component.LayoutPublic,
            children: [
              {path: '', name: 'register', component: Component.Register},
            ],
          },
          {
            path: '/accession/register',
            component: Component.LayoutPublic,
            children: [
              {path: '', name: 'accession-register', component: Component.AccessionRegister},
            ],
          },
          {
            path: '/accession/:accessionId/confirm-external-user/:userId',
            component: Component.LayoutPublic,
            children: [
              {path: '', name: 'accession-confirm-user', component: Component.AccessionConfirmExternalUser},
            ],
          },
          {
            path: '/confirm-external-user/:userId',
            component: Component.LayoutPublic,
            children: [
              {path: '', name: 'confirm-external-user', component: Component.ConfirmExternalUser},
            ],
          },
          {
            path: '/accession/:accessionId/confirm-external-user-password/:userId',
            component: Component.LayoutPublic,
            children: [
              {
                path: '', name: 'accession-confirm-user-password',
                component: Component.AccessionConfirmExternalUserPassword
              },
            ],
          },
        ]
      }
    ]
  ),
  ...applyRules(
    ['auth'],
    [
      {
        path: '/home',
        label: 'Inicio',
        name: 'home',
        icon: 'mdi-view-dashboard',
        autoRender: false,
        component: Component.LayoutPrivate,
        children: [
          {
            path: '', name: 'home', component: Component.Home,
            meta: {
              breadcrumb: [
                { title: 'Painel', disabled: true }
              ]
            }
          }
        ],
      },
      {
        path: '/profile',
        label: 'Meu perfil',
        name: 'profile',
        icon: 'mdi-view-dashboard',
        autoRender: false,
        component: Component.LayoutPrivate,
        children: [
          {
            path: '/profile', name: 'profile', component: Component.Profile,
            meta: {
              breadcrumb: [
                { title: 'Meu perfil', disabled: true }
              ]
            }
          }
        ],
      },
      {
        path: '/home',
        label: 'Painel',
        name: 'dashboard',
        icon: 'mdi-view-dashboard',
        autoRender: true,
        component: Component.LayoutPrivate,
        children: [
          {
            path: '', autoRender: true, name: 'dashboard', component: Component.Dashboard,
            meta: {
              breadcrumb: [
                { title: 'Painel', disabled: true }
              ]
            }
          }
        ],
      },
      /* Filtrar visualizacao por perfil */
      {
        path: '/accession',
        name: 'accession',
        label: 'Adesões',
        autoRender: true,
        icon: 'mdi-account-arrow-right',
        component: Component.LayoutPrivate,
        children: [
          {
            path: '', autoRender: true, label: 'Adesões', name: 'accession', component: Component.Accession,
            meta: {
              breadcrumb: [
                { title: 'Adesão', disabled: false, href: '/accession' },
              ]
            }
          },
          {
            path: 'manage/:id', autoRender: false, name: 'accession-manage', component: Component.AccessionManage,
            meta: {
              breadcrumb: [
                { title: 'Adesões', disabled: false, href: '/accession' },
                { title: 'Gerenciar Adesões', disabled: true }
              ]
            }
          },
          {
            path: 'view/:id', autoRender: false, name: 'accession-view', component: Component.AccessionManage,
            meta: {
              breadcrumb: [
                { title: 'Adesões', disabled: false, href: '/accession' },
                { title: 'Visualizar Adesões', disabled: true }
              ]
            }
          }
        ],
      },
      {
        path: '/records',
        name: 'records',
        label: 'Alertas/Visitas',
        autoRender: true,
        icon: 'mdi-alert-circle',
        component: Component.LayoutPrivate,
        children: [
          {
            path: 'create', autoRender: true, label: 'Adicionar', name: 'records-create', component: Component.FormRecordStep1,
            meta: {
              breadcrumb: [
                { text: 'Registros', disabled: false, href: '/records' },
                { title: 'Criar Alerta', disabled: true, }
              ]
            }
          },
          {
            path: 'list', autoRender: true, label: 'Ver todos', name: 'records-list', component: Component.Records,
            meta: {
              breadcrumb: [
                { text: 'Registros', disabled: false, href: '/records' },
                { title: 'Todos os registros', disabled: true, }
              ]
            }
          },
          {
            path: ':id/:step?', label: 'Etapa', name: 'step', component: Component.Step,
            meta: {
              breadcrumb: [
                { text: 'Meus Alertas', disabled: false, href: '/records' },
                { title: 'Ações', disabled: true, }
              ]
            }
          },
          {
            path: 'list?status=pending', autoRender: true,
            label: 'Alertas Pendentes', name: 'pending-alert-list', component: Component.Records,
            meta: {
              breadcrumb: [
                { text: 'Alertas', disabled: false, href: '/alerts' },
                { title: 'Meus Alertas', disabled: true, }
              ]
            }
          },
          {
            path: 'create-step2', autoRender: false, label: 'Criar Alerta', name: 'records-create-step2', component: Component.FormRecordStep2,
            meta: {
              breadcrumb: [
                { text: 'Registros', disabled: false, href: '/records' },
                { title: 'Criar Registro', disabled: true, }
              ]
            }
          },
          {
            path: 'edit/:id', autoRender: false, label: 'Editar', name: 'records-edit', component: Component.AlertsEdit,
            meta: {
              breadcrumb: [
                { text: 'Meus Alertas', disabled: false, href: '/alerts' },
                { title: 'Editar', disabled: true, }
              ]
            }
          },
          {
            path: 'view/:id', autoRender: false, label: 'Visualizar', name: 'records-view', component: Component.RecordsView,
            meta: {
              breadcrumb: [
                { text: 'Registros', disabled: false, href: '/records' },
                { title: 'Registro', disabled: true, }
              ]
            }
          },
          {
            path: ':id/is_partial', autoRender: false, label: 'Casos: Sala de Vacina', name: 'records-completed-vaccination', component: Component.CompletedVaccination,
            meta: {
              breadcrumb: [
                { text: 'Meus Alertas', disabled: false, href: '/records' },
                { title: 'Casos: Sala de Vacina', disabled: true, }
              ]
            }
          },
        ],
      },      
      {
        path: '/report',
        label: 'Relatórios',
        name: 'reports',
        icon: 'mdi-chart-bar',
        autoRender: true,
        component: Component.LayoutPrivate,
        children: [
          {
            path: '', autoRender: true, name: 'reports', component: Component.Reports,
            meta: {
              breadcrumb: [
                { title: 'Relatórios', disabled: true }
              ]
            }
          }
        ],
      },
      {
        path: '/tools',
        label: 'Ferramentas',
        name: 'tools',
        icon: 'mdi-tools',
        autoRender: true,
        component: Component.LayoutPrivate,
        children: [
          {
            path: '', autoRender: true, name: 'tools', component: Component.Tools,
            meta: {
              breadcrumb: [
                { title: 'Ferramentas', disabled: true }
              ]
            }
          }
        ],
      },
      {
        path: '/users',
        label: 'Usuários',
        name: 'users',
        icon: 'mdi-account-group',
        autoRender: true,
        component: Component.LayoutPrivate,
        children: [
          {
            path: '', autoRender: true, name: 'users-list', component: Component.UsersList,
            meta: {
              breadcrumb: [
                { title: 'Usuários', disabled: false }
              ]
            },
          },
          {
            path: 'edit/:id', autoRender: false, name: 'users-edit', component: Component.UsersEdit,
            meta: {
              breadcrumb: [
                { title: 'Editar Pessoa', disabled: true }
              ]
            },
          },
          {
            path: 'view/:id', autoRender: false, name: 'users-view', component: Component.UsersEdit,
            meta: {
              breadcrumb: [
                { title: 'Visualizar Pessoa', disabled: true }
              ]
            },
          },
          {
            path: 'create', autoRender: false, name: 'users-create', component: Component.UsersNew,
            meta: {
              breadcrumb: [
                { title: 'Adicionar Pessoa', disabled: true }
              ]
            },
          }
        ],
      },
      {
        path: '/2FA-login',
        autoRender: false,
        component: Component.LayoutPublic,
        children: [
          {path: '', name: '2FALogin', component: Component.TwoFALogin, meta: { icon: 'mdi-alert-circle' } },
        ],
      },
      {
        path: '/settings/enable-2fa',
        autoRender: false,
        component: Component.LayoutPrivate,
        children: [
          {path: '', name: 'Enable2FA', component: Component.Enable2FA, meta: { icon: 'mdi-alert-circle' } },
        ],
      },
      {
        path: '/settings/disable-2fa',
        autoRender: false,
        component: Component.LayoutPrivate,
        children: [
          {path: '', name: 'Disable2FA', component: Component.Disable2FA, meta: { icon: 'mdi-alert-circle' }},
        ],
      },
      {
        path: '/configuration',
        label: 'Configurações',
        name: 'configurations',
        icon: 'mdi-cog-outline',
        autoRender: true,
        component: Component.LayoutPrivate,
        children: [
          {
            path: '', autoRender: true, label: 'Listar', name: 'configuration-list', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                {title: 'Configurações', disabled: true}
              ]
            }
          },
          {
            path: 'target-public', autoRender: false, label: 'Público alvo', name: 'target-public', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                { text: 'Configurações', disabled: false, href: '/configuration' },
                { title: 'Público alvo', disabled: true}
              ]
            },
            children: [
              {path: '', name: 'target-public-list', component: Component.TargetPublicList},
            ],
          },
          {
            path: 'reasons-vaccine-delay', autoRender: false, label: 'Motivo Atraso', name: 'reasons-vaccine-delay', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                { text: 'Configurações', disabled: false, href: '/configuration' },
                { title: 'Motivos', disabled: true}
              ]
            },
            children: [
              {path: '', name: 'reasons-vaccine-delay-list', component: Component.ReasonsVaccineDelayList},
              {path: 'edit/:id', name: 'reasons-vaccine-delay-edit', component: Component.ReasonsVaccineDelayEdit},
              {path: 'create', name: 'reasons-vaccine-delay-new', component: Component.ReasonsVaccineDelayNew}
            ],
          },
          {
            path: 'vaccines', autoRender: false, label: 'Vacinas', name: 'vaccines', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                { text: 'Configurações', disabled: false, href: '/configuration' },
                { title: 'Vacinas', disabled: true}
              ]
            },
            children: [
              {path: '', name: 'vaccines-list', component: Component.VaccinesList},
              {path: 'edit/:id', name: 'vaccines-edit', component: Component.VaccinesEdit},
              {path: 'create', name: 'vaccines-create', component: Component.VaccinesNew}
            ],
          },
          {
            path: 'deadlines', autoRender: false, label: 'Prazos', name: 'deadlines', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                { text: 'Configurações', disabled: false, href: '/configuration' },
                { title: 'Prazos', disabled: true}
              ]
            },
            children: [
              {path: '', name: 'deadlines-list', component: Component.DeadlinesList},
              {path: 'edit/:id', name: 'deadlines-edit', component: Component.DeadlinesEdit},
              {path: 'create', name: 'deadlines-create', component: Component.DeadlinesNew}
            ],
          },
          {
            path: 'government-offices', autoRender: false, label: 'Encaminhamentos', name: 'government-offices', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                { text: 'Configurações', disabled: false, href: '/configuration' },
                { title: 'Áreas / Secretarias', disabled: true}
              ]
            },
            children: [
              {path: '', name: 'government-offices-list', component: Component.GovernmentOfficeList},
              {path: 'edit/:id', name: 'government-offices-edit', component: Component.GovernmentOfficeEdit},
              {path: 'create', name: 'government-offices-create', component: Component.GovernmentOfficeNew}
            ],
          },
          {
            path: 'menus', autoRender: false, label: 'Menus', name: 'menus', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                { text: 'Configurações', disabled: false, href: '/configuration' },
                { title: 'Menu / Municípios', disabled: true}
              ]
            },
            children: [
              {path: '', name: 'menus-list', component: Component.MenusList},
            ],
          },
          {
            path: 'menus-role', autoRender: false, label: 'Menus', name: 'menus-role', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                { text: 'Configurações', disabled: false, href: '/configuration' },
                { title: 'Menu / Perfil', disabled: true}
              ]
            },
            children: [
              {path: '', name: 'menus-role-list', component: Component.MenusRoleList},
            ],
          },
          {
            path: 'groups', autoRender: false, label: 'Grupos', name: 'groups', component: Component.ConfigurationList,
            meta: {
              breadcrumb: [
                { text: 'Configurações', disabled: false, href: '/configuration' },
                { title: 'Territórios', disabled: true}
              ]
            },
            children: [
              {path: '', name: 'groups-list', component: Component.GroupsList},
            ],
          },
        ],
      },
    ],
  ),
  {path: "/:catchAll(.*)", component: Component.NotFound}
];


// function applyRules(rules, routes) {
//   for (let i in routes) {
//     routes[i].meta = routes[i].meta || {}

//     if (!routes[i].meta.rules) {
//       routes[i].meta.rules = []
//     }
//     routes[i].meta.rules.unshift(...rules)

//     if (routes[i].children) {
//       routes[i].children = applyRules(rules, routes[i].children)
//     }
//   }

//   return routes
// }


function applyRules(rules, routes) {
  for (let i in routes) {
    routes[i].meta = routes[i].meta || {}

    if (!routes[i].meta.rules) {
      routes[i].meta.rules = []
    }
    routes[i].meta.rules.unshift(...rules)

    if (routes[i].children) {
      routes[i].children = applyRules(rules, routes[i].children)
    }
  }
  return routes
}

const routesFiltered = system.isDevelopment() ?
  routes.filter(route => {
    return [
      '',
      '/',
      '/login',
      '/logout',
      '/home',
      '/reset-password',
      '/profile',
      '/accession',
      '/users',
      '/configuration',
      '/tools',
      '/records'
    ].includes(route.path) == true
  }) :
  routes;

export default routesFiltered;
