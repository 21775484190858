import {createStore} from 'vuex'
import {UserModule} from "./modules/user";
import {LoadingModule} from "./modules/loading";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    UserModule,
    LoadingModule
  },
})
