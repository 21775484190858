import axios from "axios";
// initial state


export const LoadingModule = {
  namespaced: true,
  state: {
    loading: false,
    config: {
      headers: {
        showGlobalLoading: true
      }
    }
  },
  getters: {
    overlay: state => state.loading
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status;
    }
  },
  actions: {
    change({commit, state}) {
      let status = !state.loading;
      commit("setLoading", status);
    },
    setLoading({commit}, status) {
      commit("setLoading", status)
    },
    enableInterceptor: function ({commit, state}) {
      axios.interceptors.request.use(
        config => {
          state.config = config
          if (config?.headers?.hasOwnProperty('showGlobalLoading') && config?.headers?.showGlobalLoading === false) {
            commit("setLoading", false);
          } else {
            commit("setLoading", true);
          }
          return config;
        },
        error => {
          commit("setLoading", false);
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        response => {
          if (state.loading && state.config.headers.showGlobalLoading === undefined ) {
            commit("setLoading", false);
          }
          return response;
        },
        function (error) {
          commit("setLoading", false);
          return Promise.reject(error);
        }
      );
    }
  },
}


