import axios from 'axios';
import router from "@/router";

axios.interceptors.request.use(
  (config) => {
    // @ts-ignore
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    config.withCredentials = true;
    config.baseURL = config.url == '/sanctum/csrf-cookie' ? process.env.VUE_APP_API_BASEURL : process.env.VUE_APP_API;
    return Promise.resolve(config);
  },
  (error) => {
    Promise.reject(error)
  },
);

axios.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (error.response.status === 401 &&
      error.response.data.message === 'Unauthenticated.'
    ) {
      await router.push('/login');
      return;
    }
    return Promise.reject(error.response)
  }

);
