import store from "@/store";
import Application from "@/api/application/Application";

export default {
  isDevelopment(): boolean {
    const hostname = window.location.hostname
    // const hostname = 'buscaativavacinal.org.br'
    return hostname === 'buscaativavacinal.org.br'
  },
  async versionBack() {
    let versionBack = await store.getters["UserModule/versionBack"];
    if (!versionBack) {
      const data = await Application.version();
      versionBack = data.version
      await store.dispatch("UserModule/setVersionBack", versionBack)
    }
    return versionBack
  },
};
