import { createLogger } from 'vue-logger-plugin'
import axios from 'axios'

// after hook to send log messages to api endpoint
const ServerLogHook = {
  run: (event) => {
    console.log('ServerLogHook', event.level, event.argumentArray);
    // make a POST request to docker container in the same network
    const loggerUrl = `//logger.${window.location.hostname}/log`;
    axios.post(loggerUrl, {
      severity: event.level,
      data: event.argumentArray
    })
  }
}

// create logger with options
const logger = createLogger({
  enabled: true,
  level: 'debug',  
  afterHooks: [ServerLogHook]
})

export default logger