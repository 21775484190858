<template>
  <v-img size="100">
    <img :src="getAvatarLogo()" alt="logo-quiz"/>
  </v-img>
</template>

<script>
import logo from '@/assets/logo.svg';

export default {
  name: 'Logo',
  data: () => ({}),
  methods: {
    getAvatarLogo() {
      return logo;
    },
  },
};
</script>
