import {createApp} from 'vue';
import router from '@/router';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import {loadFonts} from './plugins/webfontloader';
import Maska from 'maska'
import Toast from "vue-toastification";
import Datepicker from 'vue3-datepicker'

import "vue-toastification/dist/index.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "./styles/global.css"
import "vue3-datepicker/dist/vue3-datepicker.css"


import './libs/axios';
import './components';

// import front-end logging
import logger from './logger';

loadFonts();

// @ts-ignore
createApp(App)
  .use(store)
  .use(Toast, {
    position: "bottom-center",
    pauseOnFocusLoss: false,
    closeOnClick: true,
    maxToasts: 3,
    newestOnTop: true,
    transition: "Vue-Toastification__fade",
    filterBeforeCreate: (toast, toasts) => {
      if (toasts.filter(
        t => t.type === toast.type
      ).length !== 0) {
        // Returning false discards the toast
        return false;
      }
      // You can modify the toast if you want
      return toast;
    }
  })
  .use(Maska)
  .use(vuetify)
  .use(router)
  .use(Maska)
  .use(logger)
  .component('Datepicker', Datepicker )
  // .directive('required', {
  //   created: function (el, binding) {
  //     console.info(el,'EL???????')
  //     console.info(binding,'EL???????')
  //     const r = document.createElement('span');
  //
  //     // l.classList.add('required');
  //     r.classList.add('required', 'sign');
  //
  //     r.appendChild(document.createTextNode('*'));
  //     el.appendChild(r);
  //     // set the colour we added to the bind
  //     // el.style.backgroundColor = binding.value ? binding.value : 'blue';
  //     // el.style.fontStyle = 'italic';
  //     // el.style.fontSize = '24px';
  //   }
  // })
  .mount('#app');
